import React from 'react';
import _ from 'lodash';
import {
  Text,
  Box,
  Badge,
  useClipboard,
  IconButton,
  HStack,
  Flex,
  VStack,
  Divider,
  useToast,
  keyframes,
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { CopyIcon } from '@chakra-ui/icons';

interface StepsProps {
  task: any,
}

const slideOver = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const CopyButton = ({ textToCopy }: any) => {
  const { onCopy, hasCopied } = useClipboard(textToCopy);
  const toast = useToast();

  const handleCopy = () => {
    onCopy();
    toast({
      title: "Copied!",
      description: "Text has been copied to clipboard.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <IconButton aria-label="copy" onClick={handleCopy} size="sm">
      <CopyIcon />
    </IconButton>
  );
};

function findCurrentStepParentAndGrandParent(task: any, parent = null, grandParent = null): any {
  if (!task.completed) {
    if (task.steps && task.steps.length > 0) {
      const newGrandParent = parent === null ? task : grandParent;

      for (let subtask of task.steps) {
        const result = findCurrentStepParentAndGrandParent(subtask, task, newGrandParent);
        if (result) return result;
      }
    } else {
      return { currentStep: task, parentStep: parent, grandParentStep: grandParent };
    }
  }

  return { currentStep: task, parentStep: parent, grandParentStep: grandParent };
}

export const Steps: React.FC<StepsProps> = ({
  task
}) => {
  if (!task) {
    return <></>;
  }

  console.log(JSON.stringify(task));

  const { currentStep, parentStep, grandParentStep } = findCurrentStepParentAndGrandParent(task);

  console.log(currentStep, parentStep, grandParentStep);

  if (currentStep.completed && !parentStep && !grandParentStep) {
    return (
      <>
        <HStack
          position="absolute"
          bottom="220px"
          left="40px"
        >
          <Text fontSize="md" mb={2} fontWeight="bold" color="#005766" opacity={0.8}>
            {currentStep.title}
          </Text>
        </HStack>
        <Flex
          alignItems="center"
          overflowX="scroll"
          width="full"
          position="absolute"
          bottom="80px"
          paddingLeft="20px"
          paddingRight="20px"
        >
          {currentStep.steps.map((step: any, index: number) => (
            <Box
              key={index}
              p={2}
              m={2}
              shadow="md"
              borderWidth="1px"
              flexShrink={0}
              flex="none"
              width="300px"
              height="130px"
              borderRadius="5px"
              backgroundColor={"#CDDABA"}
              opacity={0.8}
            >
              <HStack justifyContent="space-between">
                <Text fontSize="sm" color="#00576699" fontWeight="bold">
                  {`${index + 1}. ${step.title}`}
                </Text>
                <Badge
                  ml="4"
                  fontSize="md"
                  background="none"
                  color="#005766"
                  opacity={step.completed ? 1 : 0.2}
                >
                  {step.completed ? <MdCheckCircle color="#005766" /> : <></>}
                </Badge>
              </HStack>
              <Divider my={1} borderColor="#00576650" />
              <VStack
                align="start"
                spacing={0}
                opacity={step.stepResult ? 0.6 : 1}
                transition='opacity 1s ease-out'
              >
                {_.map(step.suggestedPrompts, (prompt: string, idx: number) => (
                  <Text key={idx} fontSize="xs" color="#00576699">{`"${prompt}"`}</Text>
                ))}
              </VStack>
              <HStack
                opacity={step.stepResult ? 0.6 : 0}
                transition='opacity 1s ease-out'
                width="100%"
                backgroundColor="#005766"
                borderRadius="5px"
                p={2}
                marginTop={2}
                height="38px"
                justify="space-between"
              >
                <Text fontSize="sm" fontWeight="bold" color="#ffffff" isTruncated>
                  {step.stepResult}
                </Text>
                {step.stepResult && step.stepResult.length && (
                  <CopyButton textToCopy={step.stepResult} />
                )}
              </HStack>
            </Box>
          ))}
        </Flex>
      </>
    );
  }

  return (
    <>
      <HStack
        position="absolute"
        bottom="220px"
        left="40px"
      >
        <Text fontSize="md" mb={2} fontWeight="bold" color="#005766" opacity={0.8}>
          {grandParentStep.title}
        </Text>
        {grandParentStep.title !== parentStep.title && (
          <Text fontSize="md" mb={2} fontWeight="bold" color="#005766" opacity={0.8}>
            {`-> ${parentStep.title}`}
          </Text>
        )}
      </HStack>
      <Flex
        alignItems="center"
        overflowX="scroll"
        width="full"
        position="absolute"
        bottom="80px"
        paddingLeft="20px"
        paddingRight="20px"
      >
        {parentStep.steps.map((step: any, index: number) => (
          <Box
            key={index}
            p={2}
            m={2}
            shadow="md"
            borderWidth="1px"
            flexShrink={0}
            flex="none"
            width="300px"
            height="130px"
            borderRadius="5px"
            backgroundColor={"#CDDABA"}
            opacity={currentStep.title === step.title ? 1 : (step.completed ? 0.8 : 0.6)}
          >
            <HStack justifyContent="space-between">
              <Text fontSize="sm" color="#00576699" fontWeight="bold">
                {`${index + 1}. ${step.title}`}
              </Text>
              <Badge
                ml="4"
                fontSize="md"
                background="none"
                color="#005766"
                opacity={step.completed ? 1 : 0.2}
              >
                {step.completed ? <MdCheckCircle color="#005766" /> : <></>}
              </Badge>
            </HStack>
            <Divider my={1} borderColor="#00576650" />
            <VStack
              align="start"
              spacing={0}
              opacity={step.stepResult ? 0.6 : 1}
              transition='opacity 1s ease-out'
            >
              {_.map(step.suggestedPrompts, (prompt: string, idx: number) => (
                <Text key={idx} fontSize="xs" color="#00576699">{`"${prompt}"`}</Text>
              ))}
            </VStack>
            <HStack
              opacity={step.stepResult ? 0.6 : 0}
              transition='opacity 1s ease-out'
              width="100%"
              backgroundColor="#005766"
              borderRadius="5px"
              p={2}
              marginTop={2}
              height="38px"
              justify="space-between"
            >
              <Text fontSize="sm" fontWeight="bold" color="#ffffff" isTruncated>
                {step.stepResult}
              </Text>
              {step.stepResult && step.stepResult.length && (
                <CopyButton textToCopy={step.stepResult} />
              )}
            </HStack>
          </Box>
        ))}
      </Flex>
    </>
  );
};
