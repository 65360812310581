import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDOAVX3gmkGm400tuyEWejejdiSv9kPgPk',
  authDomain: 'woon-2b73f.firebaseapp.com',
  projectId: 'woon-2b73f',
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const fbFunctions = getFunctions();
export const db = getFirestore();
