import {
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react"
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState, useRef } from 'react';
import { doc, getDoc } from 'firebase/firestore';

import { Record } from "./Record"
import { db, auth } from './connect';
import { signInAnon, createUser, completeLoginLinkToEmail } from './auth';

const theme = extendTheme({
  fonts: {
    body: `'Lato', sans-serif`,
  },
});

export const App = () => {
  const startedRef = useRef<boolean>(false);
  const [user, setUser] = useState<any | null>(null);
  const [isAuthStateLoaded, setIsAuthStateLoaded] = useState(false);

  useEffect(() => {
    if (!startedRef.current) {
      startedRef.current = true;

      const storedEmail = window.localStorage.getItem('emailForSignInWoon');

      if (storedEmail) {
        completeLoginLinkToEmail(storedEmail, window.location.href).catch(error => console.error(error));
      }

      onAuthStateChanged(auth, (updatedUser) => {
        console.log(updatedUser);

        if (updatedUser === null) {
          setUser(null);
          signInAnon().catch((error) => console.error('signInAnon', error));
          setIsAuthStateLoaded(true);
        } else {
          // signOut().catch((error) => console.error(error));
          getDoc(doc(db, 'users', updatedUser.uid))
            .then((docSnap) => {
              if (docSnap.exists()) {
                setUser(docSnap.data());
              } else {
                if (updatedUser.emailVerified) {
                  createUser().catch(error => console.error('createUser', error))
                }

                setUser({
                  id: updatedUser.uid,
                  isAnon: updatedUser.isAnonymous,
                  email: updatedUser.email,
                });
              }
              setIsAuthStateLoaded(true);
            })
            .catch((error) => {
              console.error('Error fetching user document:', error);
            });
        }
      });
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Record user={user} setUser={setUser} />
    </ChakraProvider>
  )
};
