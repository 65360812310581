import { useState, useEffect, useRef } from 'react';
import {
  Button,
  VStack,
  Text,
  HStack,
  Image,
  Box,
  keyframes,
  Link,
  useDisclosure,
  useClipboard,
  useToast,
  Input,
} from "@chakra-ui/react"

import { FaInstagram } from "react-icons/fa";
import _ from "lodash";
import { updateDoc, doc } from 'firebase/firestore';

import { db } from './connect';
import { SignIn, SignOut } from './SignIn';
import { AdminPanel } from './AdminPanel';
import { Steps } from './Steps';

interface Window {
  webkitAudioContext: typeof AudioContext;
  AudioContext: typeof AudioContext;
  MSStream?: any;
  location: any;
}

declare const window: Window;

const pulse = keyframes`
  0% {
    transform: scale(0.85);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const isMobileSafari = (): boolean => {
  const userAgent = navigator.userAgent;

  // For iOS devices
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

  // Check for Safari and exclude Chrome on iOS
  const isSafari = isIOS && /Safari/.test(userAgent) && !/CriOS/.test(userAgent);

  return isSafari;
};

function promiseReturns(urls: any) {
  return Promise.all(urls.map((urlData: any) => {
    let url = urlData;
    const options: any = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    };

    if (typeof url !== 'string') {
      options.method = 'POST';
      options.body = JSON.stringify(url.data);
      url = urlData.uri;
    }

    return fetch(url, options).then(r => {
      return r.ok ? r.json() : '';
    })
  }));
}

const MemoryCard = ({ text, show, showAllMemories }: { text: string; show: boolean; showAllMemories: boolean; }) => {
  const [shouldRender, setShouldRender] = useState(show);

  useEffect(() => {
    if (!show && !showAllMemories) {
      setShouldRender(false);
    }
  }, [show, showAllMemories]);

  if (!shouldRender && !showAllMemories) {
    return <></>;
  }

  return (
    <Box
      p="2" // padding
      bgColor="#CDDABA" // background color
      boxShadow="md" // medium box shadow
      borderRadius="100px" // large border radius
      border="1px" // border width
      borderColor="#A6C28C80" // border color
      animation={`${slideDown} 1s ease-out`}
      zIndex="tooltip" // Ensures it's above most other elements
      opacity={(show || showAllMemories) ? 1 : 0}
      transition='opacity 1s ease-out'
    >
      <Text
        fontSize='xs'
        color="#005766"
      >
        {text}
      </Text>
    </Box>
  );
};

export const Record = ({ user, setUser }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [value, setValue] = useState('512-877-3811');
  const { hasCopied, onCopy } = useClipboard(value);

  const handleCall = () => {
    window.location.href = `tel:${value}`;
  };

  const handleText = () => {
    window.location.href = `sms:${value}`;
  };

  const [usePromptBlocks, setUsePromptBlocks] = useState(true);
  const [playMusic, setPlayMusic] = useState(true);

  const songRef = useRef(new Audio('audio/thinking.wav'));
  const clickRef = useRef(new Audio('audio/click.mp3'));
  const fadeIntervalRef = useRef<any>(null);
  const volumeRef = useRef<boolean>(false);
  const thoughtRef = useRef<any>(null);

  const audioContextRef = useRef<AudioContext | null>(null);
  const audioSourcesRef = useRef<AudioBufferSourceNode[]>([]);
  const lastBufferEndTimeRef = useRef<number>(0);
  const playbackTimeRef = useRef<number>(0);
  const pauseRef = useRef<boolean>(false);

  const arrayBufferArrayRef = useRef<ArrayBuffer[]>([])

  const streamRef = useRef<MediaStream | null>(null);
  const firstThreadIdRef = useRef<string | null>(null);
  const threadIdRef = useRef<string | null>(null);
  const recordingRef = useRef<boolean | null>(null);
  const speakingRef = useRef<boolean | null>(null);

  const [isRecording, setIsRecording] = useState(false);
  const [speak, setSpeak] = useState(false);
  const [thinking, setThinking] = useState(false);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const woon = useRef<WebSocket | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDisconnect, setIsDisconnect] = useState(false);

  const [dialogue, setDialogue] = useState<any>([]);
  const [thought, setThought] = useState<any>('');
  const [showThought, setShowThought] = useState(false);
  const [showAllMemories, setShowAllMemories] = useState(false);

  const [memories, setMemories] = useState<any[]>([]);

  const [promptBlock, setPromptBlock] = useState<any>(null);
  const [promptStateId, setPromptStateId] = useState<any>(null);

  const userEmail = _.get(user, 'email');

  const playBuffer = async () => {
    if (!audioContextRef.current || audioContextRef.current.state === 'closed' || !arrayBufferArrayRef.current.length || pauseRef.current) {
      return;
    }

    try {
      setIsPlaying(true);
      const arrayBuffer = _.first(arrayBufferArrayRef.current) as ArrayBuffer;
      const audioBuffer: AudioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
      const source: AudioBufferSourceNode = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);

      const currentTime: number = audioContextRef.current.currentTime;
      const startTime: number = Math.max(lastBufferEndTimeRef.current, currentTime);
      source.start(startTime);

      // TODO need to playback from the pause point
      playbackTimeRef.current = 0;

      lastBufferEndTimeRef.current = startTime + audioBuffer.duration;

      arrayBufferArrayRef.current.shift();

      source.onended = () => {
        console.log('source ended', pauseRef.current);

        if (!pauseRef.current) {
          source.disconnect();
          audioSourcesRef.current.shift();
          if (audioSourcesRef.current.length === 0) {
            setIsPlaying(false);
          }
        }
      };

      audioSourcesRef.current.push(source);

      if (arrayBufferArrayRef.current.length > 0) {
        playBuffer();
      }
    } catch (error) {
      console.error('Error decoding audio data', error);
      stopRecording();
    }
  };

  const stopSources = () => {
    if (audioSourcesRef.current) {
      lastBufferEndTimeRef.current = 0;

      audioSourcesRef.current.forEach(source => {
        if (source) source.stop();
      });
      audioSourcesRef.current = [];
    }
  };

  const stopAndCloseContext = () => {
    stopSources();

    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close();
    }
  };

  useEffect(() => {
    // if (!woon.current) {
    //   woon.current = new WebSocket(process.env.REACT_APP_WOON as string);
    //   woon.current.binaryType = 'arraybuffer';

    //   woon.current.onopen = () => {
    //     console.log('Woon Connected');

    //     setIsDisconnect(false);
    //   };

    //   woon.current.onmessage = async (event) => {
    //     if (event.data) {
    //       if (event.data instanceof ArrayBuffer) {
    //         if (event.data.byteLength > 0 && audioContextRef.current) {
    //           if (songRef.current && songRef.current.volume > 0.1 && songRef.current.played && !volumeRef.current) {
    //             clearInterval(fadeIntervalRef.current);
    //             volumeRef.current = true;
  
    //             fadeIntervalRef.current = setInterval(() => {
    //               if (songRef.current.volume <= 0.04) {
    //                 clearInterval(fadeIntervalRef.current);
    //                 volumeRef.current = false;
    //               } else {
    //                 songRef.current.volume -= 0.02
    //               }
    //             }, 100);
    //           }

    //           setThought('');
    //           setThinking(false);
    //           volumeRef.current = false;
    //           arrayBufferArrayRef.current.push(event.data);

    //           if (arrayBufferArrayRef.current.length === 1) {
    //             playBuffer();
    //           } 
    //         }
    //       } else {
    //         const data = JSON.parse(event.data);

    //         console.log(data);

    //         // TODO: only interrupt if the user is speaking

    //         if (data.intent === '0') {
    //           setThought('irrelevant');
    //         } else if (data.interrupt && speakingRef.current) {
    //           setThought('interrupt');
    //         } else if (data.remembering) {
    //           setThought('remembering');

    //           setMemories((m) => {
    //             // Check if the array already contains an item with the same text
    //             const alreadyExists = m.some(item => item.text.toLowerCase() === data.remembering.toLowerCase());

    //             if (!alreadyExists) {
    //               // Add the new item if it doesn't already exist
    //               return [...m, { show: true, text: data.remembering }];
    //             } else {
    //               // Return the original array if the item already exists
    //               return m;
    //             }
    //           });

    //           setTimeout(() => {
    //             setMemories((prevMemories) => prevMemories.map(m => m.text === data.remembering ? { ...m, show: false } : m));
    //           }, 10000);

    //         } else if (data.thinking) {
    //           setThought('thinking');
    //         }

    //         if (!_.isNil(data.promptBlock)) {
    //           setPromptBlock(data.promptBlock);
    //         }

    //         setDialogue((prevDialogue: any) => [...prevDialogue, data]);

    //         if (data.threadId) {
    //           if (!threadIdRef.current && woon.current) {
    //             firstThreadIdRef.current = data.threadId;
    //           } else {
    //             threadIdRef.current = data.threadId;
    //           }
    //         }

    //         if (data.promptBlockStateId) {
    //           setPromptStateId(data.promptBlockStateId);
    //         }

    //         if (_.isEqual(data.thinking, true) || _.isEqual(data.thinking, false)) {
    //           clearInterval(fadeIntervalRef.current);

    //           fadeIntervalRef.current = setInterval(() => {
    //             if (songRef.current.volume >= 0.2) {
    //               clearInterval(fadeIntervalRef.current);
    //             } else {
    //               songRef.current.volume += 0.01
    //             }
    //           }, 100);

    //           setThinking(data.thinking);
    //         }

    //         if (data.interrupt) {
    //           console.log('REAL STOP');
    //           pauseRef.current = false;
    //           stopSources();
    //         }

    //         // if (data.pause) {
    //         //   pauseRef.current = true;
    //         //   pausePlayback();
    //         // }

    //         // if (data.resume) {
    //         //   pauseRef.current = false;
    //         //   playBuffer();
    //         // }
    //       }
    //     }
    //   };

    //   woon.current.onclose = () => {
    //     console.log('Woon Disconnected');
    //     setIsDisconnect(true);
    //     stopRecording();
    //   };

    //   woon.current.onerror = (error: any) => {
    //     console.log('Woon Error:', error);
    //     if (error.error === 1) {
    //       threadIdRef.current = firstThreadIdRef.current;
    //     }
    //   };
    // }

    // if (!mediaRecorder.current && navigator.mediaDevices) {
    //   navigator.mediaDevices.getUserMedia({ audio: { noiseSuppression: true } })
    //     .then(stream => {
    //       streamRef.current = stream;
    //       mediaRecorder.current = new MediaRecorder(stream);
    //       mediaRecorder.current.ondataavailable = async (event) => {
    //         if (event.data && event.data.size > 0) {
    //           const arrayBuffer = await event.data.arrayBuffer();

    //           if (woon.current) {
    //             woon.current.send(arrayBuffer);
    //           }
    //         }
    //       };
    //     });
    // }

    // return () => {
    //   if (mediaRecorder.current) {
    //     mediaRecorder.current.stop();
    //   }

    //   if (woon.current) {
    //     woon.current.close();
    //   }

        // if (songRef.current) {
        //   songRef.current.pause();
        // }

        // if (fadeIntervalRef.current) {
        //   clearInterval(fadeIntervalRef.current);
        // }

        // if (audioContextRef.current) {
          // stopAndCloseContext();
        //   audioContextRef.current.close();
        // }
    // }
  }, []);

  const getEmoji = (thought: string) => {
    switch (thought) {
      case 'irrelevant':
        return '❓';
      case 'interrupt':
        return '🚫';
      case 'thinking':
        return '💭';
      case 'dead':
        return '☠️';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (isRecording && streamRef.current) {
      console.log('RECORDING');

      const listenToVolume = () => {
        const audioContext = new AudioContext();
        const microphone = audioContext.createMediaStreamSource(streamRef.current as MediaStream);

        const analyzer = audioContext.createAnalyser();
        analyzer.fftSize = 2048;

        const bufferLength = analyzer.frequencyBinCount;
        const dataArray: any = new Uint8Array(bufferLength);

        microphone.connect(analyzer);

        const checkVolume = () => {
          analyzer.getByteFrequencyData(dataArray);
  
          let maxAmplitude = Math.max(...dataArray);

          let normalizedVolume = maxAmplitude / 255;

          if (normalizedVolume > 0.60 && recordingRef.current) {
            setSpeak(true);
            speakingRef.current = true;
          } else {
            setSpeak(false);
            speakingRef.current = false;
          }

          requestAnimationFrame(checkVolume);
        };
    
        checkVolume();
      };

      listenToVolume();
    }
  }, [isRecording]);

  useEffect(() => {
    setShowThought(true);

    if (thoughtRef.current) {
      clearTimeout(thoughtRef.current);
    }

    if (thought && thought !== 'thinking') {
      thoughtRef.current = setTimeout(async () => {
        setShowThought(false);
      }, 1600);
    }
  }, [thought]);

  useEffect(() => {
    if (!showThought) {
      setThought('');
    }
  }, [showThought]);

  // useEffect(() => {
  //   console.log(JSON.stringify(promptBlock));
  // }, [promptBlock])

  const startRecording = async () => {
    if (!isRecording && mediaRecorder.current && songRef.current && woon.current && clickRef.current) {

      if (!isMobileSafari() && playMusic) {
        clickRef.current.volume = 0.2;
        clickRef.current.play();

        songRef.current.loop = true;
        songRef.current.volume = 0.05;
  
        songRef.current.play().catch(error => console.error("Error playing the audio:", error));
      }

      const AudioCtx = window.AudioContext || window.webkitAudioContext;
      audioContextRef.current = new AudioCtx();

      console.log('START');
      mediaRecorder.current.start(250);

      if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
        audioContextRef.current.resume();
      }

      const userThreadId = _.get(user, 'threadId', '');
      const userId = _.get(user, 'id', '');

      if (!userThreadId && firstThreadIdRef.current && userId) {
        const userDoc = doc(db, 'users', userId);
        await updateDoc(userDoc, {
          threadId: firstThreadIdRef.current,
        });
      }

      const [allPrompts, allMyPrompts] = await promiseReturns([
        `${process.env.REACT_APP_WOON_API}promptBlocks`,
        {
          uri: `${process.env.REACT_APP_WOON_API}promptBlockStates`,
          data: userThreadId,
        }
      ]);

      // TODO convert prompt block data

    //   const promptBlockData = allPrompts..map((item) => {
    //     return JSON.parse(Buffer.from(item.data, 'binary').toString());
    // });

      console.log({allPrompts, allMyPrompts});

      // TODO need to figure out how to track promptBlockStateId

      woon.current.send(JSON.stringify({
        threadId: usePromptBlocks === false ? '' : userThreadId,
        // promptBlockStateId: 228,
        start: true,
        usePromptBlocks,
      }));

      threadIdRef.current = _.get(user, 'threadId', '');

      setIsRecording(true);
      recordingRef.current = true;
    }
  };

  const stopRecording = () => {
    if (isRecording && mediaRecorder.current) {
      setThought('');
      setShowAllMemories(false);
      setIsPlaying(false);
      stopAndCloseContext();
      mediaRecorder.current.stop();
      setIsRecording(false);
      recordingRef.current = false;

      if (songRef.current) {
        songRef.current.pause();
      }
    }
  };

  const pausePlayback = () => {
    if (!audioContextRef.current) return;

    playbackTimeRef.current = audioContextRef.current.currentTime - lastBufferEndTimeRef.current;

    console.log('PAUSE', playbackTimeRef.current);

    audioSourcesRef.current.forEach(source => {
      if (source) source.stop();
    });
  
    setIsPlaying(false);
  };

  const animation = (delay: string): undefined | string => {
    return `${pulse} infinite 1.5s ${delay} linear`;
  };

  const speechSize = 3.6;
  const mySize = 10;
  const buttonSize = 0.6;

  return (
    <Box
      width="100vw"
      height="100vh"
      backgroundColor="#DFE5CE"
      margin={0}
      padding={0}
      overflow="hidden"
    >
      <VStack spacing={4} width='100%'>
        <VStack marginTop="40px"spacing={0} width="100%">
          <Image
            src='/woon-horizontal.svg'
            height="20px"
            position="absolute"
            top="24px"
            right="24px"
            transition='opacity 1s ease-out'
            opacity={isRecording ? 1 : 0}
          />
          <Image
            src='/woon-vertical.svg'
            height="60px"
            transition='opacity 1s ease-out'
            opacity={isRecording ? 0 : 1}
          />
          <Text
            mt={4}
            fontSize="16px"
            fontFamily="Arbutus Slab"
            color="#005766"
            maxWidth="400px"
            width="80%"
            textAlign="center"
            // fontWeight="bold"
            transition='opacity 1s ease-out'
            opacity={isRecording ? 0 : 0.6}
            mb={2}
          >
            Super Power Your Group Chats
          </Text>
          <Text
            width="60%"
            mt={4}
            fontSize="14px"
            fontFamily="Arbutus Slab"
            color="#005766"
            maxWidth="400px"
            textAlign="center"
            mb={4}
          >
            Add woon to any group chat to power it up! Woon can suggest restaurants, activities, and more that are perfect for your group.
          </Text>
          {/* <Text
            fontSize="16px"
            fontFamily="Arbutus Slab"
            color="#005766"
            maxWidth="400px"
            width="80%"
            textAlign="center"
            fontWeight="bold"
            transition='opacity 1s ease-out'
            opacity={0.6}
            mb={2}
          >
            First month is free, $10/month after that
          </Text> */}
          {/* <Button
            mt={4}
            transition='opacity 1s ease-out'
            opacity={isRecording ? 0 : 1}
            onClick={onOpen}
            backgroundColor={"#306A74"}
            _hover={{
              opacity: 0.8,
            }}
            _active={{
              opacity: 0.9,
            }}
            color="#ffffff"
            position="absolute"
            top="24px"
            right="24px"
          >
            {userEmail || 'Sign In'}
          </Button> */}
          {/* <AdminPanel
            user={user}
            isRecording={isRecording}
            usePromptBlocks={usePromptBlocks}
            setUsePromptBlocks={setUsePromptBlocks}
            playMusic={playMusic}
            setPlayMusic={setPlayMusic}
          /> */}
        </VStack>
        {/* <Box position="relative" marginTop="-40px">
          <Box
            transition='opacity 1s ease-out'
            opacity={isPlaying ? 1 : 0}
          >
            <Box
              animation={animation('0.2s')}
              pointerEvents="none"
              boxSize={{ base: `${116 * speechSize}px` }}
              borderRadius="50%"
              border="2px solid"
              borderColor="rgba(21, 41, 14, 0.3)"
              position="absolute"
              left={{ base: `calc(50% - ${58 * speechSize}px)` }}
              top={{ base: `calc(50% - ${58 * speechSize}px)` }}
              opacity={0}
            />
            <Box
              animation={animation('0.1s')}
              pointerEvents="none"
              boxSize={{ base: `${104 * speechSize}px` }}
              borderRadius="50%"
              border="2px solid"
              borderColor="rgba(21, 41, 14, 0.5)"
              position="absolute"
              left={{ base: `calc(50% - ${52 * speechSize}px)` }}
              top={{ base: `calc(50% - ${52 * speechSize}px)` }}
              opacity={0}
            />
            <Box
              pointerEvents="none"
              boxSize={{ base: `${92 * speechSize}px` }}
              borderRadius="50%"
              border="2px solid"
              borderColor="rgba(21, 41, 14, 0.7)"
              position="absolute"
              left={{ base: `calc(50% - ${46 * speechSize}px)` }}
              top={{ base: `calc(50% - ${46 * speechSize}px)`  }}
            />
          </Box>
          <Box
            position="absolute" 
            left="50%" 
            top="10%"
            transform="translate(-50%, -50%)" 
            zIndex="1" 
            transition='opacity 1s ease-out' 
            opacity={showThought ? 1 : 0}
            fontSize="2em"
          >
            {getEmoji(thought)}
          </Box>
          {!isRecording ? (
            <Button
              onClick={startRecording}
              isDisabled={isRecording || !user}
              backgroundColor="transparent"
              position="absolute"
              left="56%" 
              top="36%"
              borderRadius="100%"
              _hover={{
                backgroundColor: "rgba(0, 87, 102, 0.3)"
              }}
              width="60px"
              height="60px"
              zIndex={100}
            >
              <Box
                transition='opacity 1s ease-out'
                opacity={1}
              >
                <Box
                  animation={animation('0.2s')}
                  pointerEvents="none"
                  boxSize={{ base: `${116 * buttonSize}px` }}
                  borderRadius="50%"
                  border="2px solid"
                  borderColor="rgba(0, 87, 102, 0.3)"
                  position="absolute"
                  left={{ base: `calc(50% - ${58 * buttonSize}px)` }}
                  top={{ base: `calc(50% - ${58 * buttonSize}px)` }}
                  opacity={0}
                />
                <Box
                  animation={animation('0.1s')}
                  pointerEvents="none"
                  boxSize={{ base: `${104 * buttonSize}px` }}
                  borderRadius="50%"
                  border="2px solid"
                  borderColor="rgba(0, 87, 102, 0.5)"
                  position="absolute"
                  left={{ base: `calc(50% - ${52 * buttonSize}px)` }}
                  top={{ base: `calc(50% - ${52 * buttonSize}px)` }}
                  opacity={0}
                />
                <Box
                  pointerEvents="none"
                  boxSize={{ base: `${92 * buttonSize}px` }}
                  borderRadius="50%"
                  border="2px solid"
                  borderColor="rgba(0, 87, 102, 0.7)"
                  position="absolute"
                  left={{ base: `calc(50% - ${46 * buttonSize}px)` }}
                  top={{ base: `calc(50% - ${46 * buttonSize}px)`  }}
                />
              </Box>
            </Button>
          ) : (
            <Button
              onClick={stopRecording}
              isDisabled={!isRecording}
              backgroundColor="transparent"
              position="absolute"
              left="56%" 
              top="36%"
              borderRadius="100%"
              _hover={{
                backgroundColor: "rgba(204, 217, 204, 0.3)"
              }}
              width="60px"
              height="60px"
            >
              <Box
                animation={animation('0.1s')}
                pointerEvents="none"
                boxSize={{ base: `${92 * buttonSize / 2}px` }}
                borderRadius="50%"
                border="2px solid"
                borderColor="rgba(204, 217, 204, 1)"
                position="absolute"
                left={{ base: `calc(50% - ${46 * buttonSize / 2}px)` }}
                top={{ base: `calc(50% - ${46 * buttonSize / 2}px)`  }}
              />
            </Button>
          )}
          <Text
            transition='opacity 1s ease-out'
            opacity={!isRecording ? 1 : 0}
            position="absolute"
            left="67%" 
            top="35%"
            fontFamily="Arbutus Slab"
            fontSize="14px"
            color="rgba(0, 87, 102, 1)"
            borderBottom="2px solid rgba(0, 87, 102, 0.8)"
            width="120px"
            textAlign="right"
          >
            turn on volume
          </Text>
          <Text
            transition='opacity 1s ease-out'
            opacity={!isRecording ? 1 : 0}
            marginTop="4px"
            position="absolute"
            left="72%" 
            top="40%"
            fontWeight="bold"
            fontFamily="Arbutus Slab"
            fontSize="18px"
            color="rgba(0, 87, 102, 1)"
            width="94px"
            textAlign="right"
          >
            press me
          </Text>
          <Image src='/woon-big.png' width="500px" />
        </Box>
        <Box
          transition='opacity 1s ease-out'
          opacity={speak ? 1 : 0}
          position='fixed'
          bottom={"-400px"}
          left={0}
          right={0}
        >
          <Box
            animation={animation('0.2s')}
            pointerEvents="none"
            boxSize={{ base: `${116 * mySize}px` }}
            borderRadius="50%"
            border="8px solid"
            borderColor="rgba(149, 172, 189, 0.3)"
            position="absolute"
            left={{ base: `calc(50% - ${58 * mySize}px)` }}
            top={{ base: `calc(50% - ${58 * mySize}px)` }}
            opacity={0}
          />
          <Box
            animation={animation('0.1s')}
            pointerEvents="none"
            boxSize={{ base: `${104 * mySize}px` }}
            borderRadius="50%"
            border="8px solid"
            borderColor="rgba(149, 172, 189, 0.5)"
            position="absolute"
            left={{ base: `calc(50% - ${52 * mySize}px)` }}
            top={{ base: `calc(50% - ${52 * mySize}px)` }}
            opacity={0}
          />
          <Box
            pointerEvents="none"
            boxSize={{ base: `${92 * mySize}px` }}
            borderRadius="50%"
            border="8px solid"
            borderColor="rgba(149, 172, 189, 0.7)"
            position="absolute"
            left={{ base: `calc(50% - ${46 * mySize}px)` }}
            top={{ base: `calc(50% - ${46 * mySize}px)`  }}
          />
        </Box> */}
        <Box
          borderColor={'#FFFFFF99'}
          borderWidth={4}
          borderRadius={12}
        >
          <Image
            src='./woon-qr.png'
            borderRadius={8}
            width="240px"
          />
        </Box>
        <Text
          mt={4}
          fontSize="16px"
          fontFamily="Arbutus Slab"
          color="#005766"
          maxWidth="400px"
          width="80%"
          textAlign="center"
          // fontWeight="bold"
          transition='opacity 1s ease-out'
          opacity={isRecording ? 0 : 0.6}
        >
          Scan QR Code<br/>
          <b>{value}</b>
        </Text>
        <HStack>
          <Button
            fontSize="16px"
            fontWeight="bold"
            fontFamily="monospace"
            color="#183B43"
            onClick={handleText}
          >
            Text
          </Button>
        </HStack>
        <Text
          fontSize="14px"
          fontFamily="Arbutus Slab"
          color="#005766"
          maxWidth="400px"
          width="80%"
          textAlign="center"
          transition='opacity 1s ease-out'
          opacity={0.6}
        >
          Text woon to get started!
        </Text>
        <Text
          fontSize="12px"
          fontFamily="Arbutus Slab"
          color="#005766"
          maxWidth="400px"
          width="80%"
          textAlign="center"
          transition='opacity 1s ease-out'
          opacity={0.6}
          mb={2}
        >
          By messaging, you authorize Woon to send text messages with requested and suggested information. Message/data rates apply. Consent is not a condition of purchase. See Terms & Conditions.
        </Text>
      </VStack>
      <Button
        position="fixed"
        transition='opacity 1s ease-out'
        backgroundColor={showAllMemories ? '#FFFFFF99' : '#FFFFFF80'}
        left="20px"
        top="20px"
        isDisabled={!isRecording}
        borderRadius={'100px'}
        opacity={isRecording ? 1 : 0}
        onClick={() => {
          setShowAllMemories(!showAllMemories);
        }}
        _hover={{
          opacity: 0.8,
        }}
        _active={{
          opacity: 1,
          backgroundColor: '#FFFFFF99'
        }}
        _disabled={{
          opacity: 0,
          cursor: 'default',
        }}
      >
        🧠
      </Button>
      <Box
        transition='opacity 1s ease-out'
        opacity={(isRecording && usePromptBlocks) ? 1 : 0}
      >
        <Steps task={promptBlock} />
      </Box>
      <VStack
        position="fixed"
        left="10px"
        top="60px"
        mt="4"
        ml="4"
        spacing={4}
        justify={'start'}
        align={'start'}
        maxWidth={'80%'}
      >
        {showAllMemories && !memories.length && (
          <MemoryCard text={'no memories found'} show={true} showAllMemories={showAllMemories} />
        )}
        {memories.map((memory, index) => <MemoryCard key={index} text={memory.text} show={memory.show} showAllMemories={showAllMemories} />)}
      </VStack>
      <Box
        position="absolute"
        bottom="40px"
        left="20px"
        opacity={isRecording ? 0 : 1}
        transition='opacity 1s ease-out'
      >
        <Text
          color="#00000080"
          fontSize={"xx-small"}
        >
          © Woon, Inc. All rights reserved. ❤️ Austin, TX
        </Text>
      </Box>
      <HStack
        position="absolute"
        spacing={4}
        bottom="20px"
        left="20px"
        opacity={isRecording ? 0 : 1}
        transition='opacity 1s ease-out'
      >
        <Link
          href="https://app.termly.io/document/terms-of-service/c3c8f020-e0de-4040-b7d5-03b1976a48f2"
          isExternal
          color="#00576699"
          _hover={{ textDecoration: 'underline' }}
          fontSize={"xs"}
        >
          Terms of Service
        </Link>
        <Link
          href="https://app.termly.io/document/privacy-policy/83c9d6e7-eeec-45f6-9945-2c8f675ceca1"
          isExternal
          color="#00576699"
          _hover={{ textDecoration: 'underline' }}
          fontSize={"xs"}
        >
          Privacy Policy
        </Link>
        <Link
          href="mailto:howdy@woon.ai"
          color="#00576699"
          _hover={{ textDecoration: 'underline' }}
          fontSize={"xs"}
        >
          howdy@woon.ai
        </Link>
        <Link
          href="https://www.instagram.com/woon_together/"
          isExternal
          color="#00576699"
          _hover={{ color: '#005766' }}
          fontSize={"xs"}
        >
          <FaInstagram />
        </Link>
      </HStack>
      {/* {userEmail ? (
        <SignOut isOpen={isOpen} onClose={onClose} />
      ) : (
        <SignIn isOpen={isOpen} onClose={onClose} />
      )} */}
    </Box>
  );
};
