import { httpsCallable } from 'firebase/functions';
import { signInAnonymously, signInWithEmailLink, sendSignInLinkToEmail, isSignInWithEmailLink } from 'firebase/auth';

import { auth, fbFunctions } from './connect';

export const signIn = async (providerData: any, signer: string, account: string) => {
  // const verifySigning = httpsCallable<
  //   {
  //     providerData: any;
  //     signer: string;
  //     account: string;
  //   },
  //   {
  //     token: string;
  //   }
  // >(fbFunctions, 'verifySignedMessage');

  // const verifySignResponse = await verifySigning({
  //   providerData,
  //   signer,
  //   account,
  // });

  // await signInWithCustomToken(auth, verifySignResponse.data.token);
};

export const sendLoginLinkToEmail = async (email: string) => {
  console.log(process.env.NODE_ENV);

  const actionCodeSettings = {
    url: process.env.NODE_ENV === 'production' ? 'https://woon.ai/' : 'http://localhost:3000',
    handleCodeInApp: true,
  };

  await sendSignInLinkToEmail(auth, email, actionCodeSettings);
  window.localStorage.setItem('emailForSignInWoon', email);
};

export const completeLoginLinkToEmail = async (email: string, windowLocationHref: string) => {
  if (isSignInWithEmailLink(auth, windowLocationHref)) {
    await signInWithEmailLink(auth, email, windowLocationHref);
  }
};

export const signInAnon = async () => {
  await signInAnonymously(auth);
  const createAnonUser = httpsCallable(fbFunctions, 'createAnonUser');
  await createAnonUser();
};

export const signOut = async () => {
  await auth.signOut();
};

export const createUser = async () => {
  const callCreateUser = httpsCallable(fbFunctions, 'createUser');
  await callCreateUser();
};

